<!--
 * @Description: 首页容器
 * @Autor: zhangzhang
 * @Date: 2021-06-10 16:20:52
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-01-14 21:59:38
-->
<template>
  <div class="main">
    <!-- 侧边菜单 -->
    <ul class="main-meau" v-if="false">
      <img
        class="w80 auto-center mt10 mb30"
        src="../../assets/image/zhibai.png"
      />
      <template v-for="(item, index) in meauList">
        <router-link :to="{ path: item.path }">
          <li class="main-meau-item">
            <i class="icon" :class="item.icon"></i>
            <span>{{ item.name }}</span>
          </li>
        </router-link>
      </template>
    </ul>

    <!-- 内容 -->
    <div class="main-body">
      <AdminBar></AdminBar>
      <div class="main-body-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import AdminBar from "@/components/AdminBar";
import { mapMutations, mapGetters } from "vuex";
import { settingProject } from "@/utils/auth";

export default {
  name: "mall",

  components: {
    AdminBar,
  },

  created() {
    // 默认设置首页为当前正在构建页面
    // this.setHomePage();

    // 利用闭包保存project数据,在刷新时保存
    let temp = this.project;

    // 当页面刷新时，把当前项目数据保存localStorage中
    window.addEventListener("beforeunload", function (e) {
      settingProject(temp);
    });
  },

  data() {
    return {
      meauList: [
        {
          name: "首页",
          icon: "icon-hot1",
          path: "/admin/home/data",
        },
        {
          name: "升级授权",
          icon: "icon-hot1",
          path: "/admin/upgrade/license",
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["project"]),
  },

  methods: {
    ...mapMutations(["setHomePage"]),
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  height: 100vh; /*no*/
  background: #f7f8fa;

  .main-body {
    flex: 1;
    background: #154598;

    .main-body-content {
      width: 100%;
      position: relative;
      background: #ffffff;
      height: calc(100vh - 60px); /*no*/
      overflow-y: scroll;
      border-radius: 20px 0 0 0;
    }
  }
}

.main-meau {
  width: 100px; /*no*/
  height: 100%;
  padding-top: 16px; /*no*/
  background: #154598;
  overflow-y: scroll;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

  .main-meau-item {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px; /*no*/
    padding: 11px 20px;
    color: #fafbfc;
    cursor: pointer;

    i {
      margin-right: 5px;
      font-size: 18px; /*no*/
      color: #fafbfc;
    }
  }

  .active {
    i {
      color: #ffffff !important;
    }
    .main-meau-item {
      position: relative;
      background: #527fcc;
      color: #ffffff;

      &::after {
        position: absolute;
        top: 10px; /*no*/
        right: 10px; /*no*/
        content: "";
        width: 4px; /*no*/
        height: 21px; /*no*/
        border-radius: 3px; /*no*/
        background: #fff;
      }
    }
  }
}
</style>
