<!--
 * @Description: PC头部菜单
 * @Autor: zhangzhang
 * @Date: 2021-05-19 14:07:29
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-02-04 15:13:33
-->
<template>
  <div>
    <div class="topBar">
      <!-- 左侧内容 -->
      <span class="f14 pointer cor" @click="back">
        <img class="w80 mr40" src="../../assets/image/zhibaiTitle.png" />
        ⏎返回
      </span>
      <div class="menu">
        <template v-for="(item, index) in meauList">
          <router-link :to="{ path: item.path }">
            <li class="main-meau-item">
              <i class="icon" :class="item.icon"></i>
              <span>{{ item.name }}</span>
            </li>
          </router-link>
        </template>
      </div>
      <!-- 右侧内容 -->
      <div class="rt">
        超级管理员
        <el-button size="small ml10" @click="onLogout">退出</el-button>
      </div>
    </div>

    <real-timeView :show.sync="show"></real-timeView>
    <real-timeView-pc :show.sync="PCshow"></real-timeView-pc>

    <save-dialog ref="save"></save-dialog>

    <qr-dialog ref="qr"></qr-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { editProject } from '@/api/project'

export default {
  data() {
    return {
      show: false,
      PCshow: false,
      developers: 2,
      meauList: [
        {
          name: '首页',
          icon: 'el-icon-house',
          path: '/admin/home'
        },
        {
          name: '项目',
          icon: 'el-icon-files',
          path: '/admin/project'
        },
        {
          name: '用户',
          icon: 'el-icon-user',
          path: '/admin/user'
        },
        {
          name: '代理商',
          icon: 'el-icon-data-line',
          path: '/admin/agent'
        },
        {
          name: '财务',
          icon: 'el-icon-coin',
          path: '/admin/money'
        },
        {
          name: '网站',
          icon: 'el-icon-odometer',
          path: '/admin/website'
        },
        {
          name: '升级授权',
          icon: 'el-icon-upload2',
          path: '/admin/upgrade'
        },
        {
          name: '系统',
          icon: 'el-icon-setting',
          path: '/admin/system'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['project', 'userInfo'])
  },
  mounted() {
    // 获取开发者模式状态
    let develop = localStorage.getItem('developers')
    if (develop) {
      this.developers = develop
    } else {
      this.developers = 2
    }
  },
  methods: {
    ...mapMutations(['logout']),

    // 开发者模式
    developersChange() {
      if (this.developers == 1) {
        this.$confirm('是否开启管理员模式功能?', '退出', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.developers = 2
          this.$message.success('已开启管理员模式')
          localStorage.setItem('developers', 2)
          location.reload()
        })
      } else {
        this.$confirm('是否开启开发者模式功能?', '退出', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.developers = 1
          this.$message.success('已开启开发者模式')
          localStorage.setItem('developers', 1)
          location.reload()
        })
      }
    },
    // 返回商城管理
    back() {
      this.$router.push({ name: 'choice' })
    },
    previewPC() {
      this.PCshow = true
    },
    preview() {
      this.show = true
    },
    openSave(view = false) {
      if (process.env.NODE_ENV == 'production') {
        if (this.project.del == 2) {
          this.$message.error('演示项目不可操作，请切换项目')
          return
        }
      }

      this.$refs.save.open(view)
    },

    toSchema() {
      let { href } = this.$router.resolve({
        path: '/schema'
      })
      window.open(href)
    },

    viewQr() {
      if (process.env.NODE_ENV == 'production') {
        if (this.project.del == 2) {
          this.$message.error('演示项目不可操作，请切换项目')
          return
        }
      }

      this.$confirm('如不更新，则预览为上次保存的项目数据?', '是否更新保存', {
        confirmButtonText: '更新保存',
        cancelButtonText: '不更新',
        type: 'warning'
      })
        .then(() => {
          this.openSave(true)
        })
        .catch(() => {
          this.openQr()
        })
    },

    openQr() {
      this.$refs.qr.open()
    },

    // 保存
    async save() {
      let data = {
        id: this.project.id,
        userId: this.userInfo.userId,
        name: this.project.name,
        richText: JSON.stringify(this.project)
      }

      let { status } = await editProject(data)

      if (status == '10000')
        this.$notify({
          title: '成功',
          message: '项目保存成功',
          type: 'success'
        })
    },

    // 退出
    onLogout() {
      this.$confirm('是否确定退出平台?', '退出', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.logout()
        this.$router.push({ name: 'login' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  padding: 0 20px;
  background: #154598;
  position: relative;
  left: -2px;
  box-shadow: 6px 0px 5px 1px rgb(0 0 0 / 10%);

  .main-meau-item {
    display: flex;
    align-items: center;
    width: 130px;
    justify-content: center;
    font-size: 14px; /*no*/
    padding: 11px 20px;
    color: #fafbfc;
    height: 50px;
    cursor: pointer;

    i {
      margin-right: 5px;
      font-size: 18px; /*no*/
      color: #fafbfc;
    }
  }

  .active {
    i {
      color: #ffffff !important;
    }
    .main-meau-item {
      position: relative;
      background: #527fcc;
      color: #ffffff;
      border-radius: 5px 5px 0 0;
      height: 50px;

      //   &::after {
      //     position: absolute;
      //     bottom: 2px; /*no*/
      //     right: 30px; /*no*/
      //     content: '';
      //     width: 21px; /*no*/
      //     height: 4px; /*no*/
      //     border-radius: 3px; /*no*/
      //     background: #fff;
      //   }
    }
  }
  .menu {
    display: flex;
    width: 70%;
    position: relative;
    top: 4px;
    > div {
      width: 100px;
      color: #ffffff;
    }
  }
  .cor {
    color: #ffffff;
    display: flex;
    align-items: center;
  }
  .bt {
    background: #154598;
    color: #ffffff;
  }
  .rt {
    color: #ffffff;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: 'Courier New', Courier, monospace;
  }
  button {
    color: #ffffff;
    background: #154598;
  }
}
</style>
